import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  public showToaster: BehaviorSubject<Toaster> = new BehaviorSubject({
    visible: false,
  } as Toaster);
}

export interface Toaster {
  message?: string;
  statue?: string;
  title?: string;
  visible: boolean;
}
